import React, { useState } from 'react';

const filters = ["Created", "Something else",  "Price - Lowest to Highest", "Price - Highest to Lowest "]

export default function Search(props) {
	const [term, setTerm] = useState("")
	const [tags, setTags] = useState([])
	const [showFilters, setShowFilters] = useState(false)

	function updateTerm(e){
		setTerm(e.target.value)
	}

	const removeTag = (tag) => {
	    setTags(tags.filter(t => t !== tag));
	};

	function handleKeyDown(key){
		if(key === 13){
			setTags([term, ...tags])
			setTerm("")
		}
	}

	function handleFilter(i){
		console.log("i", i)
		toggleFilters()
	}

	function toggleFilters(){
		setShowFilters(!showFilters)
	}

    return (  
    	<div className="search" onKeyDown={(e) => handleKeyDown(e.keyCode)}>
	    	<div className="search-container">
	    		
    			<div class="input-group">
    				<div className="input-group-search">
    					<svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
	    				<input 
	    					class="" value=""
	    					aria-invalid="false" 
	    					aria-required="false" 
	    					autocomplete="on" 
	    					inputmode="text" 
	    					name="" 
	    					placeholder="Search here..." 
	    					type="text" 
	    					value={term}
	    					onChange={e => updateTerm(e)}
	    				/>
	    			</div>

    				<div className="tags">
        				{tags.map((d,i) => {
        					return(
        						<span className="tag hover" aria-label={d + ", close by backspace"} role="button" tabindex="0" >
        							<span title="hello" class="ai fy fz g0 g1">{d}</span>
	            					<span aria-hidden="true" role="presentation" onClick={() => removeTag(d)}>
	            						<svg title="Delete" viewBox="5 5 13.186 13.186" class="dd de df me mf">
	            							<title>Delete</title>
	            							<path fill-rule="evenodd" clip-rule="evenodd" d="M7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L12 10.5858L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L13.4142 12L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L10.5858 12L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289Z"></path>
	            						</svg>
	            					</span>		
	            				</span>
        					)
        				})}
        			</div>
	            </div>
		            		
	            
	            <div className="block relative">
		            <div className="filter pl-4 pr-4" onClick={toggleFilters}>
		            	<div className="distribute distribute-start" style={{alignItems: "center"}} >
			            	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
			            	<span className="pl-1">Filter</span>
			            </div>
			            <div>
			            	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
			            </div>



		            </div>
		            {(showFilters) ? 
				            <div className="filters-dropdown">
				            	<ul>
				            		{filters.map((d,i) => {
				            			return(
				            				<li onClick={() => handleFilter(i)}>
				            					{d}
				            				</li>
				            			)
				            		})}
				            	</ul>

				            	<div className="overlay" onClick={toggleFilters}></div>
				            </div>
				        : ""}
		        </div>

	            
	            		
	    		
	    	</div>
	    </div>
    )
}