import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import Card from "./Card";
import GridCard from "./GridCard";
import Floater from "./Floater";
import Notice from "./Notice";
import Poster from "./Poster";

import { collections } from "../data/collections";

export default function Hero(props) {
  return (
    <div
      className='hero block relative full-width text-center clearfix'
      style={{ backgroundImage: "url(/assets/Vector.svg)", backgroundPosition: "top center", width: "100%", overflow: "hidden", backgroundRepeat: "no-repeat" }}
    >
      {/*<svg xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="xMinYMin meet" viewBox="0 0 200 100"
		      xmlns="http://www.w3.org/2000/svg">
		      <g>
		      	   	<defs>
					    <pattern id="uno" patternUnits="userSpaceOnUse" width="100%" height="100%">
						    <image href={"/assets/bg1.jpg"} x="0" y="0" width="100%" height="100%" />
						 </pattern>
				  	</defs>
			      	<path id="svg-text" d="M 0 50 H 200" fill="url(#uno)" stroke="black"/>
			      	<path id="svg-text2" d="M 0 60 H 180" fill="transparent" stroke="black"/>
		          	<text
		          		x={0}
		          		y={0}
		          	>
			          	<textPath
			                xlinkHref="#svg-text"
			                method="stretch"
			                lengthAdjust="spacingAndGlyphs"
			                fill="url(#uno)"
			              >Fine Art for <tSpan>yeee</tSpan></textPath>
		          	</text>
		          	<text 
		          		x={0}
		          		y={0}
		          	>
			          	<textPath
			                xlinkHref="#svg-text2"
			                method="stretch"
			                lengthAdjust="spacingAndGlyphs"
			                fill="url(#uno)"
			              >Web3.0</textPath>
		          </text>
		      </g>
		  </svg>*/}
      <div className='pt-8'>
        <span className='pt-8 block fontExtraBold fontSizeN1 grey2 uppercase mb-3' style={{ letterSpacing: 2 }}>
          The Future of History
        </span>
        <h1>
          <img src={"/assets/headline.png"} style={{ maxWidth: "80%" }} alt='Fine Art for Web3.0' />
        </h1>
        <p className='fontRegular fontSize1 grey2 pt-5 pb-4 block relative' style={{ fontWeight: 300, width: 600, maxWidth: "80%", margin: "0 auto" }}>
          We curate, you collect. Choose any piece from our real world artworks to mint as an NFT and digitally display anywhere. Happy minting!
        </p>
      </div>

      <div className='button-group'>
        <Link to='/collection/drop1'>
          <Button type='primary' text='View Latest' hasIcon={true} icon='right-arrow' />
        </Link>

        <Button type='secondary' text='Consign Works' />
      </div>

      <div className='full-width pt-8 mt-8 clearfix'>
        <Notice text={"Ahoy! This is a totally new thing for you to see!"} subText={"Subline text describing the offer."} />
      </div>

      <div className='floaters'>
        <Floater top={60} left={70} z={2}>
          <Card artwork={{ image: "https://source.unsplash.com/random/300×300/?art" }} height={248} width={166} />
        </Floater>

        <Floater top={240} left={-20} z={1}>
          <Card artwork={{ image: "https://source.unsplash.com/random/300×300/?nft" }} height={210} width={180} />
        </Floater>

        <Floater top={500} left={40} z={1}>
          <Card artwork={{ image: "https://source.unsplash.com/random/300×300/?painting" }} height={138} width={200} />
        </Floater>

        <Floater top={40} right={-50} z={1}>
          <Poster artwork={{ image: "https://source.unsplash.com/random/300×300/?art,painting" }} color={"#3B4664"} height={132} width={163} />
        </Floater>

        <Floater top={260} right={80} z={1}>
          <Card artwork={{ image: "https://source.unsplash.com/random/300×300/?digital&art" }} height={248} width={174} />
        </Floater>

        <Floater top={470} right={-40} z={1}>
          <Card artwork={{ image: "https://source.unsplash.com/random/300×300/?nft" }} height={150} width={220} />
        </Floater>
      </div>

      <div className='pt-8 mt-2 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Our latest collection
        </h3>
        <p className='mt-2 fontRegular mb-6'>A subline about what is below</p>

        <div className='collection-tiles clearfix pb-6'>
          <div className='column pt-4'>
            <Card artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?monkey,painting" }} height={380} width={"100%"} />
            <div className='mt-2 hide-md'>
              <Poster artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?monkey&painting" }} color={"orange"} height={152} width={"100%"} />
            </div>
          </div>
          <div className='column pt-8 hide-md'>
            <Card artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?painting" }} height={418} width={"100%"} />
            <div className='mt-2'>
              <Poster artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?digital,art" }} color={"#3B4664"} height={132} width={"100%"} />
            </div>
          </div>
          <div className='column'>
            <Card artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?digital,art" }} height={254} width={"100%"} />
            <div className='mt-2 hide-md'>
              <Card artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?digital,art" }} height={180} width={"100%"} />
            </div>
            <div className='mt-2'>
              <Card artwork={{ name: 'abc', artist: 'abc', price: '0.1', image: "https://source.unsplash.com/random/300×300/?art" }} height={180} width={"100%"} />
            </div>
          </div>
        </div>

        <div className='button-group mb-8'>
        <Link to='/collection/drop1'>

          <Button type='primary' text='View the whole collection' hasIcon={true} icon='right-arrow' />
        </Link>

          {/* <Button type='secondary' text='View Past Collections' /> */}
        </div>

        <div className='mt-8 pt-6 clearfix'>
          <div className='title-button big' style={{ backgroundImage: "url(/assets/bg2.jpg)" }}>
            <div className='title-button-container'>
              <span className='headline' style={{ letterSpacing: 2, color: "#fff" }}>
                NFTs done the Appian way
              </span>
              <h3>
                Appian Way gives physical artworks a second life in the metaverse and gives NFTs utility in the <span className='action'>real world.</span>
              </h3>
            </div>
          </div>
        </div>

        <div className='icon-columns'>
          <div className='icon-column'>
            <img src='/assets/icons/people.svg' style={{ margin: 0 }} />
            <h4>Collaborative curation</h4>
            <p>Appian Way parters with museums and galleries to create a space where historical artifacts and modern pieces converge into curated collections.</p>
          </div>

          <div className='icon-column'>
            <img src='/assets/icons/3dcube.svg' style={{ margin: 0 }} />
            <h4>3D Storage</h4>
            <p>
              Appian Way stores NFTs in 3D with a single pose jpeg for backwards compatibility. Meaning, you can display works in 2d with the added feature of rotating viewing angle up to 360 degrees.
            </p>
          </div>

          <div className='icon-column'>
            <img src='/assets/icons/key.svg' style={{ margin: 0 }} />
            <h4>Secure Storage</h4>
            <p>Each NFT minted on Appian Way is stored on Arweave, so your art is permanent and protected. </p>
          </div>

          <div className='icon-column'>
            <img src='/assets/icons/3d-square.svg' style={{ margin: 0 }} />
            <h4>3D Display</h4>
            <p>
              Users can display digital twins in the same way they display real world art, along with the ability to scale pieces to size and rotate viewing angle. Place the piece in your physical
              environment and view it using augmented reality., feature your collection on your smart-tv, or create and immersive virtual reality exhibit.{" "}
            </p>
          </div>

          <div className='icon-column'>
            <img src='/assets/icons/scanning.svg' style={{ margin: 0 }} />
            <h4>Sub-Millimeter LiDAR Capture</h4>
            <p>Our intake process ensures that each piece minted on Appian Way maintains the textures, depth, and shape of the original work to create a high-resolution digital twin for display.</p>
          </div>

          <div className='icon-column'>
            <img src='/assets/icons/truck-tick.svg' style={{ margin: 0 }} />
            <h4>Biweekly drops</h4>
            <p>
              Every two weeks Appian Way will release a new collection on the galleries page, featuring consigned works from a variety of museums and galleries. Users can add purchased digital works
              to their collections at home, and resell on major NFT platforms.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className='mt-8 pt-4 clearfix'>
        <div className='title-button' style={{ backgroundImage: "url(/assets/bg4.jpg)" }}>
          <div className='title-button-container'>
            <div className='title-text-group text-left'>
              <span className='pb-1 block fontExtraBold fontSizeN1 grey2 uppercase' style={{ letterSpacing: 2, color: "#fff" }}>
                TL;DR No more paying thousands for a jpeg.
              </span>
              <h3 style={{ margin: 0, padding: 0, maxWidth: "70%", fontSize: 34, lineHeight: "40px", paddingBottom: 16 }}>Link your wallet and start your collection today!</h3>
              <Button type='primary' text='Button' hasIcon={true} icon='right-arrow' />
            </div>

            <img src='https://source.unsplash.com/random/317×170/?crypto&wallet' style={{ width: 317, height: 170 }} />
          </div>
        </div>
      </div>

      <div className='pt-8 mt-2 mb-8 pb-8 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Past Collections
        </h3>
        <p className='mt-2 fontRegular actionSecondary'>Checkout what we've dropped in the past</p>

        <div className='grid-cards'>
          {collections.map((d, i) => {
            return <GridCard data={d} index={i} key={i} />;
          })}
        </div>
      </div>
    </div>
  );
}
