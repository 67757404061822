import React, { useEffect, useState } from "react";
import { useWeb3 } from "@3rdweb/hooks";
import Web3 from "web3";

const useContract = (type, address) => {
  const { provider } = useWeb3();
  const [contract, setContract] = useState(null);

  useEffect(() => {
    if (!provider) return;

    const web3 = new Web3(provider.provider);

    async function init() {
      switch (type) {
        case "collection":
          const collection = require(`../contracts/${process.env.REACT_APP_NETWORK}/Collection.json`);
          const c = new web3.eth.Contract(collection.abi, collection.address);
          return setContract(c);

        case "artwork":
          const artwork = require(`../contracts/${process.env.REACT_APP_NETWORK}/Artwork.json`);
          const a = new web3.eth.Contract(artwork.abi, address);
          return setContract(a);
        default:
          return null;
      }
    }
    init();
  }, [type, provider]);

  return { contract };
};


function getArtwork(address, provider) {
  const web3 = new Web3(provider?.provider)
  
  const artwork = require(`../contracts/${process.env.REACT_APP_NETWORK}/Artwork.json`);
  const a = new web3.eth.Contract(artwork.abi, address);
  
  return { contract: a }
}

export { useContract, getArtwork };
