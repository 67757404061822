import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";

import Footer from "../components/Footer"
import Artwork from "../components/Artwork"
import Nav from "../components/Nav"

function Home({ artworkAddress }) {
  
  return (
  	<div> 
        <Nav
         />
        <Artwork artworkAddress={artworkAddress} />
        <Footer />
    </div>
  );
  
}

export default withRouter(Home)