import React from 'react';

export default function Notice(props) {
	
    return (  
    	<div className="notice distribute distribute-between" style={{backgroundImage: "url(/assets/bg3.jpg)"}}>
    		<div>
	            <p className="text">{props.text}</p>
	            <p className="subtext">{props.subText}</p>
	        </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
        </div>
    )
}