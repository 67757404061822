import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import Card from "./Card";
import GridCard from "./GridCard";
import Floater from "./Floater";
import Notice from "./Notice";
import Poster from "./Poster";
import p1 from "../art/p1.jpg";
import p2 from "../art/p2.jpg";
import p3 from "../art/p3.jpg";
import p4 from "../art/p4.jpg";
import p5 from "../art/p5.jpg";
import p6 from "../art/p6.jpg";

import { collections } from "../data/collections";

export default function SplashHero(props) {
  return (
    <div className='pt-4 clearfix'>
      <div
        className='hero block relative full-width text-center clearfix'
        style={{ backgroundImage: "url(/assets/Vector.svg)", backgroundPosition: "top center", width: "100%", overflow: "hidden", backgroundRepeat: "no-repeat", marginTop: 0 }}
      >
        <div style={{ maxWidth: 1380, margin: "0 auto", position: "relative", display: "block" }}>
          <div className='pt-8'>
            <img src='/assets/splash-logo.png' />
            <span className='block fontExtraBold fontSizeN1 grey2 uppercase pb-3' style={{ letterSpacing: 2, maxWidth: "80%", margin: "0 auto" }}>
              NFTs done the Appian Way. Coming September 1st
            </span>
            <p className='fontRegular fontSize1 grey2 pt-0 pb-0 block relative' style={{ fontWeight: 300, width: 600, maxWidth: "80%", margin: "0 auto" }}>
              Appian Way NFTs utilizes submillimeter LiDAR scanning to create a digital twin of any fine art piece. Our NFTs are high resolution 3D renderings of physical pieces. Paintings,
              sculptures, pottery, we can do it all. Our first collection is a collaboration with The Art Spirit Gallery, a fine art institution located in northern Idaho for the past 25 years. Join
              us September 1st to begin collecting fine art and historical artifacts!
            </p>
          </div>

          <div className='floaters splash'>
            <Floater top={60} left={70} z={2}>
              <Card artwork={{ image: p1 }} isStill={true} height={248} width={166} />
            </Floater>

            <Floater top={240} left={-20} z={1}>
              <Card artwork={{ image: p2 }} isStill={true} height={210} width={180} />
            </Floater>

            <Floater top={500} left={40} z={1}>
              <Card artwork={{ image: p3 }} isStill={true} height={138} width={200} />
            </Floater>

            <Floater top={40} right={-50} z={1}>
              <Poster artwork={{ image: p4 }} color={"#3B4664"} isStill={true} height={132} width={163} />
            </Floater>

            <Floater top={260} right={80} z={1}>
              <Card artwork={{ image: p5 }} isStill={true} height={248} width={174} />
            </Floater>

            <Floater top={470} right={-40} z={1}>
              <Card artwork={{ image: p6 }} isStill={true} height={150} width={220} />
            </Floater>
          </div>
        </div>

        <div className='mt-2 clearfix'>
          <div className='icon-columns'>
            <div className='icon-column'>
              <img src='/assets/icons/people.svg' style={{ margin: 0 }} />
              <h4>Collaborative curation</h4>
              <p>Appian Way partners with museums, galleries and private collectors to create a space where historical artifacts and modern pieces converge in curated collections of NFTs.</p>
            </div>

            <div className='icon-column'>
              <img src='/assets/icons/key.svg' style={{ margin: 0 }} />
              <h4>Secure Storage</h4>
              <p>Each NFT minted on Appian Way is stored on Arweave, so your art is permanent and protected. </p>
            </div>

            <div className='icon-column'>
              <img src='/assets/icons/scanning.svg' style={{ margin: 0 }} />
              <h4>Sub-Millimeter LiDAR Capture</h4>
              <p>Our intake process ensures that each piece minted on Appian Way maintains the textures, depth, and shape of the original work to create a high-resolution digital twin for display.</p>
            </div>

            <div className='icon-column'>
              <img src='/assets/icons/3dcube.svg' style={{ margin: 0 }} />
              <h4>3D Storage</h4>
              <p>
                Appian Way stores NFTs in 3D with a single pose jpeg for backwards compatibility. Meaning, you can display works in 2d with the added feature of rotating viewing angle up to 360
                degrees.
              </p>
            </div>

            <div className='icon-column'>
              <img src='/assets/icons/3d-square.svg' style={{ margin: 0 }} />
              <h4>3D Display</h4>
              <p>
                Users can display digital twins in the same way they display real world art, along with the ability to scale pieces to size and rotate viewing angle. Place the piece in your physical
                environment and view it using augmented reality., feature your collection on your smart-tv, or create and immersive virtual reality exhibit.{" "}
              </p>
            </div>

            <div className='icon-column'>
              <img src='/assets/icons/truck-tick.svg' style={{ margin: 0 }} />
              <h4>Biweekly drops</h4>
              <p>
                Every two weeks Appian Way will release a new collection on the galleries page, featuring consigned works from a variety of museums and galleries. Users can add purchased digital works
                to their collections at home, and resell on major NFT platforms.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className='fontRegular fontSizeN1 text-center grey2 pt-2 pb-5 block uppercase relative' style={{ fontWeight: 300, width: 540, fontSize: 14, maxWidth: "80%", margin: "0 auto" }}>
        For more information, contact us at info@appianway.art, or call (509) 919-1855.
      </p>
    </div>
  );
}
