import Web3 from "web3"

export const artworkBase64ToJson = (str) => {
  str = str.replace("data:application/json;base64,", "")
  const artworkJson = JSON.parse(Buffer.from(str, "base64").toString())
  artworkJson.image = artworkJson.image.replace("ar://", "https://arweave.net/")
  return artworkJson
}

export const jsonFromArtwork = async (artwork, wallet) => {
  const name = await artwork.methods.title().call({ from: wallet })
  const artist = await artwork.methods.artist().call({ from: wallet })
  const description = await artwork.methods.desc().call({ from: wallet })
  const year = await artwork.methods.year().call({ from: wallet })
  const medium = await artwork.methods.medium().call({ from: wallet })
  const provenance = await artwork.methods.provenance().call({ from: wallet })
  const numMinted = await artwork.methods.counter().call({ from: wallet })
  const totalSupply = await artwork.methods.supply().call({ from: wallet })
  // const arId = await artwork.methods.arId().call({ from: wallet });
  const price = await artwork.methods.mintPrice().call({ from: wallet })
  // const uri = "https://appian-way.s3.us-west-2.amazonaws.com/10.glb"
  const uri = await artwork.methods.uri().call({ from: wallet })
  // const image = "https://arweave.net/" + arId;
  // const image = "https://arweave.net/" + arId;

  return { address: artwork._address, name, artist, description, year, medium, numMinted, totalSupply, provenance, price: Web3.utils.fromWei(price), uri }
}
