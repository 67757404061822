import React, { createRef, useEffect, useState } from "react"

import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { AmbientLight, DirectionalLight, HemisphereLight } from "three"

const ThreeDModelView = ({ url, height = 360, width = 360, idOfContainer="", ...rest }) => {
  const [hasRendered, setHasRendered] = useState(false)

  let camera, scene, renderer

  const container = document.getElementById("bruh" + idOfContainer)
  console.log('ID OF CONTAINER PROP: ', idOfContainer)
  console.log('CONTAINER ID: ', container?.id)
  camera = new THREE.PerspectiveCamera(50, 1, 0.5, 2000)
  camera.position.set(0, 2, 12)

  scene = new THREE.Scene()

  useEffect(() => {
    if (!url || url == "") return
    // if(container)
    // if(hasRendered) return;
    scene = new THREE.Scene()
    scene.add(new THREE.AmbientLight())
    // scene.background = new THREE.Color(100, 100, 200)

    renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.setSize(width, height, width + height)
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding

    // render()

    const loader = new GLTFLoader()
    loader.load(url, function (gltf) {
      scene.add(gltf.scene)
      render()
      addLights()
    })
    // });

    if (container) {
      container.appendChild(renderer.domElement)
    }

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.addEventListener("change", render) // use if there is no animation loop
    controls.minDistance = 2
    controls.maxDistance = 50
    controls.target.set(0, 1, -0.2)
    controls.update()

    // window.addEventListener("resize", onWindowResize);
  }, [url, container])

  function addLights () {
    // const state = this.state;

    // if (this.options.preset === Preset.ASSET_GENERATOR) {
    //   const hemiLight = new HemisphereLight();
    //   hemiLight.name = 'hemi_light';
    //   this.scene.add(hemiLight);
    //   this.lights.push(hemiLight);
    //   return;
    // }

    const light1  = new AmbientLight(0xFFFFFF, 0.3);
    light1.name = 'ambient_light';
    camera.add( light1 );

    const light2  = new DirectionalLight(0xFFFFFF, 0.8*Math.PI);
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = 'main_light';
    camera.add( light2 );

    scene.add(camera)
    // this.lights.push(light1, light2);
  }

  function render() {
    renderer.render(scene, camera)
    setHasRendered(true)
  }

  return (
    <div className="distribute">
      {/* {document.getElementById('bruh')?.hasChildNodes == false && <p>BROOOOO</p>} */}
      <div id={"bruh" + idOfContainer}>{rest.children}</div>
    </div>
  )
}

export default ThreeDModelView
