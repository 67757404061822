import React from 'react';


export default function Item(props) {
    return(
        <div className="distribute distribute-vertical">
            <label className="mb-0 pb-0 uppercase" style={{color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em"}}>{props.label}</label>
            <p className="mt-1 pt-0 mb-0 pb-0 fontHeavy fontSize1">{props.value}</p>
            {(props.subText) ? 
                <p className="grey2 text-left mt-1 pt-0" style={{fontSize: 12}}>{props.subText}</p>
            : ""}
        </div>
    )
}