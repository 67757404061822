import React, { useState, useEffect } from "react";
import { useWeb3 } from "@3rdweb/hooks";
import web3 from "web3";

import Item from "./Item";
import Button from "./Button";

import { useContract } from "../hooks";
import { jsonFromArtwork } from "../util";
import ThreeDModelView from "./3DModelView";

export default function ArtworkItem({ artworkAddress }) {
  const { address } = useWeb3();
  const { contract: nft } = useContract("artwork", artworkAddress);
  const [artwork, setArtwork] = useState({});
  const [isMinting, setIsMinting] = useState(false)

  

  useEffect(() => {
    // COMBAK make this a hook useArtwork(id)
    async function getArtwork() {
      if (!nft) return;
      const _artwork = await jsonFromArtwork(nft, artworkAddress)
      setArtwork(_artwork);
    }
    getArtwork();
  }, [nft]);

  async function mint() {
    setIsMinting(true)
    try {
      console.log(nft.methods);
      await nft.methods.mint().send({
        gasPrice: 2000000000,
        gasLimit: 500000,
        from: address,
        value: web3.utils.toWei("0.001"),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsMinting(false)
      alert('congrats, you minted an NFT. This will be replaced by a much prettier alert modal of course')
    }
  }

  console.log(artwork.image);
  return (
    <div className='artwork-item'>
      <div className='column'>
        <div className='distribute distribute-start distribute-vertical'>
          <ThreeDModelView url={artwork.uri} />
          {/* {artwork?.image ? <img src={artwork.image} style={{ borderRadius: 8 }} alt='img.png' /> : null} */}
          {/* <img src='/assets/art/art.jpg' style={{ borderRadius: 8 }} /> */}
          {/*<div className="card no-hover" style={{height: 250, width: "100%", boxShadow: "none", background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.7) 100%)", overflow: "hidden", border: "3.16px solid #fff"}}>
			            <div style={{backgroundImage: "url(/assets/art/art.jpg)", height: "100%", width: "100%", top: 0, left: 0, position: "absolute", backgroundSize: "cover", zIndex: 0}}></div>
			    	</div>*/}

          <div style={{ padding: 16, backgroundColor: "#fff", marginTop: 16, width: "100%", borderRadius: 8, overflow: "hidden" }}>
            <div className='user' style={{ float: "left", marginLeft: 0 }}>
              <div className='avatar' style={{backgroundColor: 'magenta', height: 40, width: 40}} />
              <div className='distribute distribute-vertical'>
                <p className='fontBold grey2' style={{ marginBottom: 2 }}>
                  Jundt Art Center
                </p>
                <p className='fontSizeN1 hover' style={{ color: "orange" }}>
                  View Collection
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='distribute distribute-between mt-4' style={{ alignItems: "baseline" }}>
          <Item label='Price' value={artwork?.price + ' ETH'} subText={"Approximately $" + (Number(artwork?.price) * 1200).toFixed(2)} />
          <Item label='Total Minted' value={artwork.numMinted + '/' + artwork.totalSupply} />
        </div>

        <div className='button-group mt-2' style={{ justifyContent: "flex-start" }}>
          <Button type='primary' text='Mint Artwork' onClick={mint} isLoading={isMinting}/>

          <button className='button tertiary'>
            <div className='distribute distribute-center'>
              <svg
                style={{ marginLeft: 0, marginRight: 16 }}
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-share-2'
              >
                <circle cx='18' cy='5' r='3'></circle>
                <circle cx='6' cy='12' r='3'></circle>
                <circle cx='18' cy='19' r='3'></circle>
                <line x1='8.59' y1='13.51' x2='15.42' y2='17.49'></line>
                <line x1='15.41' y1='6.51' x2='8.59' y2='10.49'></line>
              </svg>
              <span>Share</span>
            </div>
          </button>
        </div>
      </div>

      <div className='column'>
        <div className='text-left mb-3 clearfix'>
          <label className='mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
            Title
          </label>
          <p className='fontBold pt-0 mt-0 mb-0 pb-0' style={{ fontSize: 48, lineHeight: "56px" }}>
            {artwork?.name}
          </p>
        </div>

        <div className='text-left mb-3 clearfix'>
          <label className='mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
            Artist / Creator
          </label>
          <p className='pt-0 mt-0 fontSize1' style={{ fontWeight: 300 }}>
            {artwork?.artist}
          </p>
        </div>

        <div className='text-left mb-3 clearfix'>
          <label className='mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
            Description
          </label>
          <p className='pt-0 mt-0 fontSize1' style={{ fontWeight: 300 }}>
            {artwork.description}
          </p>
        </div>

        <div className='text-left mb-3 clearfix'>
          <label className='mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
            Provenance
          </label>
          <p className='pt-0 mt-0 fontSize1' style={{ fontWeight: 300 }}>
            {artwork.provenance}
          </p>
        </div>

        <div className='distribute distribute-between mt-4'>
          <Item label='Year' value={artwork.year} />
          <Item label='Geography' value='Made in America' />
          <Item label='Period' value='20th Century' />
          <Item label='Culture' value='American' />
        </div>

        <div className='distribute distribute-between mt-4'>
          <Item label='Dimensions' value='' subText='' />
          <Item label='Consigner' value='Art Spirit Gallery' subText="Coeur d' Alene, Idaho" />
        </div>

        <div className='text-left mt-4 mb-2 clearfix'>
          <label className='mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
            Currently available at
          </label>

          <div className='distribute distribute-inline' style={{ alignItems: "center" }}>
            <div className='distribute distribute-inline pr-2 hover' style={{ color: "orange" }}>
              <svg
                className='mr-1'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginTop: 5 }}
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              >
                <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71'></path>
                <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71'></path>
              </svg>
              <p className='mt-0 mb-0 pt-0 pb-0' style={{ borderBottom: "2px solid" }}>
                Jundt Art Center
              </p>
            </div>
            <div className='distribute distribute-inline hover' style={{ color: "orange" }}>
              <svg
                className='mr-1'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginTop: 5 }}
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
              >
                <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71'></path>
                <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71'></path>
              </svg>
              <p className='mt-0 mb-0 pt-0 pb-0' style={{ borderBottom: "2px solid" }}>
                OpenSea
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
