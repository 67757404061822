import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Suspense, useEffect, useState } from "react";
import { ThirdwebWeb3Provider, useWeb3, useSwitchNetwork } from "@3rdweb/hooks";

import Home from "./pages/Home";
import ThreeDModelView from "./components/3DModelView";
import Minting from "./pages/Minting";

import Featured from "./pages/Featured";
import Past from "./pages/Past";

import Splash from "./pages/Splash";
import axios from 'axios';


import About from "./pages/About";
import Contact from "./pages/Contact";

const supportedChainIds = [3, 4];
// const supportedChainIds = [1, 4, 137, 43113];
const connectors = {
  injected: {},
  magic: {
    apiKey: "pk_...", // Your magic api key
    chainId: 1, // The chain ID you want to allow on magic
  },
  walletconnect: {},
  walletlink: {
    appName: "thirdweb - demo",
    url: "https://thirdweb.com",
    darkMode: false,
  },
};

function App() {


  return (
    <ThirdwebWeb3Provider supportedChainIds={supportedChainIds} connectors={connectors}>
      
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path='/' render={() => <Splash />} />
            {/* <Route exact path='/3d' render={() => <ThreeDModelView />} /> */}
            <Route exact path='/collection/drop1' render={() => <Featured />} />
            <Route exact path='/collection/drop1/:artworkAddress' render={({ match }) => <Minting artworkAddress={match.params.artworkAddress} />} />
            {/**/} <Route exact path='/' render={() => <Home />} />
            <Route exact path='/collection/rousseau/:artworkAddress' render={({ match }) => <Minting artworkAddress={match.params.artworkAddress} />} />
            <Route exact path='/past' render={() => <Past />} /> 
            <Route exact path='/about' render={() => <About />} />
            <Route exact path='/contact' render={() => <Contact />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThirdwebWeb3Provider>
  );
}

export default App;
