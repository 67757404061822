import React from "react"
import ThreeDModelView from "./3DModelView"

export default function Poster({ height, width, color, onClick, artwork }) {
  if (!artwork || !artwork.uri)
    return (
      <div className="poster" style={{ overflow: "hidden", backgroundColor: color }} onClick={onclick}>
        <div style={{ height, width, backgroundSize: "contain", zIndex: 0 }}>
        </div>

        <div className="distribute distribute-end mt-1" style={{ height: "100%", zIndex: 1 }}>
          <div className="distribute distribute-vertical full-width">
            <p className="full-width text-left fontSizeN1 uppercase relative">{artwork?.name}</p>

            <div className="distribute distribute-between full-width" style={{ opacity: 0.7 }}>
              <p className="fontSizeN1">{artwork?.artist}</p>
              <p className="fontSizeN1">{artwork?.price} ETH</p>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="poster" style={{ overflow: "hidden", backgroundColor: color }} onClick={onclick}>
      {/*<img src={image} style={{height: height, width: width}}/>*/}
      {/* <div style={{height: height, width: width, backgroundImage: "url("+artwork?.image+")", backgroundSize: "cover", b
                orderRadius: 4}}></div> */}
      <div style={{ height, width, backgroundSize: "contain", zIndex: 0 }}>
        <ThreeDModelView height={360} width={360} url={artwork.uri} idOfContainer={artwork.address} />
      </div>

      <div className="distribute distribute-end mt-1" style={{ height: "100%", zIndex: 1 }}>
        <div className="distribute distribute-vertical full-width">
          <p className="full-width text-left fontSizeN1 uppercase relative">{artwork?.name}</p>

          <div className="distribute distribute-between full-width" style={{ opacity: 0.7 }}>
            <p className="fontSizeN1">{artwork?.artist}</p>
            <p className="fontSizeN1">{artwork?.price} ETH</p>
          </div>
        </div>
      </div>
    </div>
  )
}
