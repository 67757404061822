import React from "react";
import { ClipLoader } from "react-spinners";

export default function Button(props) {
  function getIcon(icon) {
    switch (icon) {
      case "right-arrow":
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' strokeLinecap='round' strokeLinejoin='round'>
            <line x1='5' y1='12' x2='19' y2='12'></line>
            <polyline points='12 5 19 12 12 19'></polyline>
          </svg>
        );
      case "align-right":
        return (
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'>
            <line x1='21' y1='10' x2='7' y2='10'></line>
            <line x1='21' y1='6' x2='3' y2='6'></line>
            <line x1='21' y1='14' x2='3' y2='14'></line>
            <line x1='21' y1='18' x2='7' y2='18'></line>
          </svg>
        );

      default:
        return "nope";
    }
  }
  return (
    <button className={"button " + props.type + " distribute distribute-horizontal distribute-center"} onClick={props.onClick} disabled={props.isLoading === true}>
      {props.hasIcon ? (
        <div className='distribute distribute-center'>
          <span>{props.text}</span>
          {getIcon(props.icon)}
        </div>
      ) : (
        <>{props.text}</>
      )}

      <div style={{ margin: 0, marginLeft: 2, padding: 0 }}>{props.isLoading === true && <ClipLoader size={16} color='white' />}</div>
    </button>
  );
}
