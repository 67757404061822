import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import Button from "../components/Button";
import Card from "../components/Card";
import GridCard from "../components/GridCard";
import Floater from "../components/Floater";
import Notice from "../components/Notice";
import Poster from "../components/Poster";
import Search from "../components/Search"

import Footer from "../components/Footer"

import Nav from "../components/Nav"
import { collections } from "../data/collections";


const drops = ["May 2022, Drop 1", "May 2022, Drop 2"]

function About(props) {
  	useEffect(() => {
		document.body.style.background = "url('/assets/texture.jpg')";
	}, [])

  return (
  	<div> 
        <Nav />
         
	      	<div className='about pt-2 mt-2 clearfix'>
		        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
		          About Us
		        </h3>
		        <div className="full-width text-center">
		        	<p className='mt-2 fontRegular actionSecondary mb-6'>Who we are</p>
		        </div>


		        <div className="bio">
			        <div className="grid1of2">
			        	<img src="/assets/founder.png" />
			        </div>
	 
			        <div className="grid1of2">
			        	<p className="fontBold" style={{fontSize: 34, padding: 0, margin: 0}}>From Our Founder</p>
			        	<p>Creative expression has always been my method of navigating the world I live in. When I was a kid, I would renovate, and re-renovate, and re-re-renovate my room nearly every other week to reflect whatever thoughts or feelings I was experiencing. It was like my version of a diary. I spent hours in the garage making DIY decorations, throwing paint around, and creating something that I could look at every day and be proud of. At 18, I landed a job as an intern at a local art gallery. In my time there, I became the curator for the gallery,  and watched my hobby blossom into a passionate love of the arts.</p>

	<p>My parents founded an Augmented Reality company when I was 6 years old, revolutionary technology has felt about as familiar to me as riding a bike. I was talking about computer vision at the dinner table while I still believed in the tooth fairy. I spent many evenings coloring in the boardroom while my parents pitched a technology that most wrote off as a “buzzword.”</p>

	<p>At 19, the idea for Appian Way was born. Appian Way is a combination of the things that make me who I am - the arts, technology, and a permanent residence on the cutting edge. It is a love letter to a childhood filled with endless innovation, books about neural networks, and acrylic paint on the furniture. Thank you for stopping by!</p>

	<p>Perris Rhayne, CEO</p>
			        </div>
			      </div>
		       
		        

		        

		       

		        <div className='mt-8 pt-6 mb-8 clearfix'>
          <div className='title-button big' style={{ backgroundImage: "url(/assets/bg2.jpg)" }}>
            <div className='title-button-container'>
              <span className='headline' style={{ letterSpacing: 2, color: "#fff" }}>
                The future of history
              </span>
              <h3>
                Fine Art for Web3.0
              </h3>

              <div>
              	<div className='icon-columns' style={{marginTop: 0, paddingTop: 0}}>
		          <div className='icon-column'>
		            <img src='/assets/icons/book-saved.png' style={{ margin: 0 }} />
		            <h4 style={{paddingRight: 8}}>Fine art is an anthology of where we have been.</h4>
		          </div>

		          <div className='icon-column'>
		            <img src='/assets/icons/element-equal.png' style={{ margin: 0 }} />
		            <h4>Web3 is the blueprint of where we will go.</h4>
		          </div>

		          <div className='icon-column'>
		            <img src='/assets/icons/arrow-2.png' style={{ margin: 0 }} />
		            <h4>Appian Way is he bridge between them.</h4>
		          </div>
		        </div>

              </div>


            </div>
          </div>
        </div>

        	<div>
        		<h3 className='fontBold text-center actionSecondary mb-0 pb-0 pt-4' style={{ fontSize: 60 }}>
		          About Us
		        </h3>
		        <div className="full-width text-center">
		        	<p className='mt-2 fontRegular actionSecondary mb-6'>Who we are</p>
		        </div>


		        <div className="team"> 
			        <div className="team-card">
			        	<img src="/assets/team/blair.png"/>
			        	<div style={{padding: 8}}>
			        		<p>Blair Williams</p>
			        		<p>Chief Strategy Officer</p>
			        	</div>

			        	<div className="icons">
		        			<img src="/assets/icons/linkedin-dark.svg" />
		        			<img src="/assets/icons/twitter-dark.svg" />
			        	</div>
			        </div>

			        <div className="team-card">
			        	<img src="/assets/team/luke.png"/>
			        	<div style={{padding: 8}}>
			        		<p>Luke Richey</p>
			        		<p>Board Chairman</p>

			        		
			        	</div>

			        	<div className="icons">
		        			<img src="/assets/icons/linkedin-dark.svg" />
		        			<img src="/assets/icons/twitter-dark.svg" />
			        	</div>
			        </div>
			    </div>

        	</div>

	      

      
    		</div>
        <Footer />
    </div>
  );
  
}

export default withRouter(About)