import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";

import Footer from "../components/Footer"
import SplashHero from "../components/SplashHero"
import Nav from "../components/Nav"

function Splash(props) {
  useEffect(() => {
		document.body.style.background = "url('/assets/texture.jpg')";
	}, [])
  return (
  	<div> 
        
        <SplashHero />
        
    </div>
  );
  
}

export default withRouter(Splash)