import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useWeb3 } from "@3rdweb/hooks";

import Button from "./Button";
import Logo from "./Logo";
import User from "./User";

export default function Nav(props) {
  const [showNav, setShowNav] = useState(false);
  const { address, chainId, connectWallet, getNetworkMetadata } = useWeb3();
//   const { switchNetwork } = useSwitchNetwork();

  function toggleNav() {
    setShowNav(!showNav);
  }

  return (
    <div className='nav'>
      <div className='nav-bar' style={{ maxWidth: 960, margin: "0 auto" }}>
        <div className='distribute distribute-between'>
          <Logo address={address}/>

          <div className='distribute distribute-horizontal'>
            {address != null ? (
              <div className='user hide-lg' style={{ marginRight: 24, paddingTop: 4 }}>
                <div className='avatar' style={{backgroundColor: 'lime', height: 40, width: 40}} />
                <div className='distribute distribute-vertical'>
                  <p className='fontBold grey2'></p>
                  <p className='fontSizeN1 hover'>0x...{address.substring(address.length - 5, address.length)}</p>
                </div>
              </div>
            ) : (
              <div className='mr-2 hide-lg'>
                <Button onClick={() => connectWallet("injected")} type='primary' text='Connect' />
              </div>
            )}

            <Button type='secondary' text='Menu' hasIcon={true} icon='align-right' onClick={toggleNav} />
          </div>
        </div>
      </div>

      <div
        className={showNav ? "nav-modal yes" : "nav-modal no"}
        style={{ background: "linear-gradient(0deg, rgba(11, 11, 38, 0.95), rgba(11, 11, 38, 0.95))", backgroundImage: "url(/assets/bgNav.jpg)" }}
      >
        <div className='section'>
          <div className='close'>
            <svg width='45' height='45' viewBox='0 0 45 45' fill='none' xmlns='http://www.w3.org/2000/svg' onClick={toggleNav}>
              <circle cx='27.3333' cy='17.6654' r='17.0833' fill='#30739A' />
              <path d='M15.665 29.3371L25.3342 19.668' stroke='white' stroke-width='2.5625' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M25.3342 29.3371L15.665 19.668' stroke='white' stroke-width='2.5625' stroke-linecap='round' stroke-linejoin='round' />
              <path
                d='M15.3753 41.5846H25.6253C34.167 41.5846 37.5837 38.168 37.5837 29.6263V19.3763C37.5837 10.8346 34.167 7.41797 25.6253 7.41797H15.3753C6.83366 7.41797 3.41699 10.8346 3.41699 19.3763V29.6263C3.41699 38.168 6.83366 41.5846 15.3753 41.5846Z'
                stroke='white'
                stroke-width='2.5625'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
            <div className='hide-lg'>
              <User address={address}/>
            </div>
          </div>

          <Logo theme='dark' />
          <div></div>
        </div>

        <div className='section'>
          <ul>
            <li>
              <Link to='/'>Home</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li>
            <li>
              <Link to='/collection/drop1'>Featured Collection</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li>
            {/* <li>
              <Link to='/collection/drop1'>Past Collection</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li> */}
            <li>
              <Link to='/about'>About Us</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li>
            {/* <li>
              <Link to='/'>Work with Us</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li> */}
            <li>
              <Link to='/'>Contact Us</Link>
              <div className='bg' style={{ backgroundImage: "url(/assets/bg1.jpg)" }}></div>
            </li>
          </ul>

          <div className='user-footer'>
            <User />
          </div>
        </div>
      </div>
    </div>
  );
}
