import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import Button from "../components/Button";
import Card from "../components/Card";
import GridCard from "../components/GridCard";
import Floater from "../components/Floater";
import Notice from "../components/Notice";
import Poster from "../components/Poster";
import Search from "../components/Search"

import Footer from "../components/Footer"

import Nav from "../components/Nav"
import { collections } from "../data/collections";


const drops = ["May 2022, Drop 1", "May 2022, Drop 2"]

function Past(props) {
  	useEffect(() => {
		document.body.style.background = "url('/assets/texture.jpg')";
	}, [])

  return (
  	<div> 
        <Nav />
         
	      	<div className='pt-2 mt-2 clearfix'>
		        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
		          Past Collections
		        </h3>
		        <div className="full-width text-center">
		        	<p className='mt-2 fontRegular actionSecondary mb-6'>NFTs done the Appian Way</p>
		        </div>

		        <Search />


		        {drops.map((d,i) => {
		        	return(
		        		<>
		        			<h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 48 }}>
					          {d}
					        </h3>
					        <div className="full-width text-center" style={{maxWidth: 640, margin: "0 auto"}}>
					        	<p className='mt-2 fontRegular actionSecondary mb-6'>Lorem ipsum dolor sit amet, viris sapientem ad vim. Ei per diam error definitiones. Timeam quaerendum ei eam, et ius equidem nusquam incorrupte.</p>
					        </div>
		        			<div className='collection-tiles clearfix pb-6'>
					          <div className='column' style={{marginTop: 0}}>
					            <Card image={"https://source.unsplash.com/random/300×300/?monkey,painting"} height={400} width={"100%"} />
					            <div className='mt-2 hide-md'>
					              <Poster image={"https://source.unsplash.com/random/300×300/?monkey&painting"} color={"orange"} height={260} width={"100%"} />
					            </div>
					          </div>

					          <div className='column hide-md' style={{marginTop: 0}}>
					            <Card image={"https://source.unsplash.com/random/300×300/?painting"} height={460} width={"100%"} />
					            <div className='mt-2'>
					              <Poster image={"https://source.unsplash.com/random/300×300/?digital,art"} color={"#3B4664"} height={200} width={"100%"} />
					            </div>
					          </div>

					          <div className='column' style={{marginTop: 0}}>
					            <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={300} width={"100%"} />
					            <div className='mt-2 hide-md'>
					              <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={180} width={"100%"} />
					            </div>
					            <div className='mt-2'>
					              <Card image={"https://source.unsplash.com/random/300×300/?art"} height={224} width={"100%"} />
					            </div>
					          </div>
					        </div>

					        <div className='button-group mb-8'>
					          <Button type='primary' text='View the whole collection' hasIcon={true} icon='right-arrow' />

					          {/*<Button type='secondary' text='View Past Collections' />*/}
					        </div>
		        		</>
		        	)
		        })}

		        

		        

		       

		        
	      

      <div className='pt-8 mt-2 mb-8 pb-8 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Past Collections
        </h3>
        <div className="full-width text-center">
        	<p className='mt-2 fontRegular actionSecondary'>Checkout what we've dropped in the past</p>
        </div>

        <div className='grid-cards'>
          {collections.map((d, i) => {
            return <GridCard data={d} index={i} key={i} />;
          })}
        </div>
      </div>
    </div>
        <Footer />
    </div>
  );
  
}

export default withRouter(Past)