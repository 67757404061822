import React from 'react';

export default function Logo(props) {
	
    return (  
    	<div>
    		{(props.theme === "dark") ? 
    			<img className="logo" src={"/assets/logo/logo-white.svg"} />
    		: <img className="logo" src={"/assets/logo/logo.svg"} />}
        </div>
    )
}