import React from "react"
import ThreeDModelView from "./3DModelView"
import { MoonLoader } from "react-spinners"

export default function Card({ height, width, artwork, onClick, isStill = false }) {
  if ((!artwork || !artwork.uri) && !isStill)
    return (
      <div className="card" style={{ height: height, width: width, background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.7) 100%)", overflow: "hidden" }} onClick={onClick}>
        <div className="distribute distribute-center" style={{ height: "100%", width: "100%", top: 0, left: 0, position: "absolute", backgroundSize: "cover", zIndex: 0 }}>
          {(!artwork || !artwork.uri || artwork.uri == "") && (
            <div style={{}}>
              <MoonLoader />
            </div>
          )}
        </div>

        <div className="distribute distribute-end" style={{ height: "100%", zIndex: 1 }}>
          <div className="distribute distribute-vertical full-width">
            <p className="full-width text-left fontSizeN1 uppercase relative">{artwork?.name}</p>

            <div className="distribute distribute-between full-width" style={{ opacity: 0.7 }}>
              <p className="fontSizeN1">{artwork?.artist}</p>
              <p className="fontSizeN1">{artwork?.price} ETH</p>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="card" style={{ height: height, width: width, background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.7) 100%)", overflow: "hidden" }} onClick={onClick}>
      {/* <div style={{backgroundImage: "url("+ artwork?.image +")", height: "100%", width: "100%", top: 0, left: 0, position: "absolute", backgroundSize: "cover", zIndex: 0}}></div> */}
      {isStill ? (
        <div style={{ backgroundImage: "url(" + artwork?.image + ")", height: "100%", width: "100%", top: 0, left: 0, position: "absolute", backgroundSize: "cover", zIndex: 0 }}></div>
      ) : (
        <div style={{ height: "100%", width: "100%", top: 0, left: 0, position: "absolute", backgroundSize: "cover", zIndex: 0 }}>
          {!artwork || !artwork.uri || artwork.uri == "" ? <p>brooooo</p> : <ThreeDModelView height={360} width={360} url={artwork.uri} idOfContainer={artwork.address} />}
        </div>
      )}

      <div className="distribute distribute-end" style={{ height: "100%", zIndex: 1 }}>
        <div className="distribute distribute-vertical full-width">
          <p className="full-width text-left fontSizeN1 uppercase relative">{artwork?.name}</p>

          <div className="distribute distribute-between full-width" style={{ opacity: 0.7 }}>
            <p className="fontSizeN1">{artwork?.artist}</p>
            <p className="fontSizeN1">{artwork?.price} ETH</p>
          </div>
        </div>
      </div>
    </div>
  )
}
