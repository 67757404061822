import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import Button from "../components/Button"

import Footer from "../components/Footer"

import Nav from "../components/Nav"
import { collections } from "../data/collections";


const drops = ["May 2022, Drop 1", "May 2022, Drop 2"]

function About(props) {
  	useEffect(() => {
		document.body.style.background = "url('/assets/texture.jpg')";
	}, [])

  return (
  	<div> 
        <Nav />
         
	      	<div className='about pt-2 mt-2 clearfix'>
		        {/*<h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
		          About Us
		        </h3>
		        <div className="full-width text-center">
		        	<p className='mt-2 fontRegular actionSecondary mb-6'>Who we are</p>
		        </div>*/}


		        <div className="bio">
			        <div className="grid1of2">
			        	<img style={{ borderRadius: 8, boxShadow: "0 0 4px 4px #fff, 0 0 0 6px rgba(255, 255, 255, 1)"}} src="/assets/mona.jpg" />
			        </div>
	 
			        <div className="grid1of2">
			        	<label className='mt-4 mb-0 pb-1 block uppercase' style={{ color: "#6E92A6", fontWeight: 800, fontSize: 12, letterSpacing: "0.125em" }}>
				            Museum and Gallery Parnerships
				          </label>
			        	<p className="fontBold" style={{fontSize: 34, padding: 0, margin: 0}}>Contact for Consignment</p>
			        	<p className='mt-0 pt-0 mb-6 fontRegular actionSecondary'>We look forward to collaborating!</p>
			        	
			        	<div className="contact">
			        		<input placeholder="Name" />
			        		<input placeholder="Email Address" />

			        		<select name="partnerType" placeholder="Type of Partner">
			        			<option value="">Type of Partner</option>
							  	<option value="uno">Uno</option>
							  	<option value="dos">Dos</option>
							  	<option value="tres">Tres</option>
							</select>

							<select name="numberOfPieces">
								<option value="">How Many Pieces Are You Interested in Consigning?</option>
							  	<option value="5">0-4</option>
							  	<option value="10">5-9</option>
							  	<option value="19">10-19</option>
							  	<option value="20 plus">20+</option>
							</select>

							<textarea placeholder="Any Works With Special Significance?" style={{height: 180, marginBottom: 16}}></textarea>

							<Button 
	                            text="Send Message"
	                            type="primary"
                        	/>
						</div>
			        </div>
			      </div>
		       
		        

		        

		       



	      

      
    		</div>
        <Footer />
    </div>
  );
  
}

export default withRouter(About)