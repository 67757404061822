import React from 'react';

export default function Card(props) {
    function getGridType(i, img){
        switch(i){
            case 0: return  <div className={"grid distribute distribute-between"}>
                                <div className="distribute distribute-vertical full-width pr-1">
                                    <div className="grid-item sm full-width mb-1" style={{backgroundImage: "url("+img[0]+")"}}></div>
                                    <div className="grid-item sm full-width" style={{backgroundImage: "url("+img[1]+")"}}></div>
                                </div>
                                <div className="grid-item lg" style={{backgroundImage: "url("+img[2]+")"}}></div>
                            </div>
             case 1: return <div className={"grid distribute distribute-between"}>
                                <div className="grid-item lg" style={{backgroundImage: "url("+img[2]+")"}}></div>
                                <div className="distribute distribute-vertical full-width pl-1">
                                    <div className="grid-item sm full-width mb-1" style={{backgroundImage: "url("+img[0]+")"}}></div>
                                    <div className="grid-item sm full-width" style={{backgroundImage: "url("+img[1]+")"}}></div>
                                </div>
                                
                            </div>
             case 2: return <div className={"grid distribute distribute-vertical"}>
                                <div className="grid-item" style={{backgroundImage: "url("+img[2]+")", width: "100%", height: 120}}></div>
                                <div className="distribute distribute-horizontal full-width pt-1">
                                    <div className="grid-item sm full-width mr-1" style={{backgroundImage: "url("+img[0]+")"}}></div>
                                    <div className="grid-item sm full-width" style={{backgroundImage: "url("+img[1]+")"}}></div>
                                </div>
                                
                            </div>
            default: return "uno"
        }
    }

    return (  
    	<div className="grid-card-group">
            <h4 className="fontBold fontSize1 actionSecondary mb-0 pb-0 text-left full-width">{props.data.date}</h4>
            <p className="fontRegular fontSizeN1 full-width actionSecondary mt-0 pt-0 mb-3 text-left ">Number of items</p>

            <div className="full-width block clearfix">
                {getGridType(props.index, props.data.images)}
            </div>
    	</div>
    )
}