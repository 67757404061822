import React from 'react';

export default function Floater(props) {
    return (  
    	<div className="floater" style={{position: "absolute", left: props.left, right: props.right, top: props.top, zIndex: props.z}}>

            {props.children} 
    		
    	</div>
    )
}