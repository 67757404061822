import React, { useEffect } from "react";

import Button from "./Button";
import Card from "./Card";
import GridCard from "./GridCard";
import Floater from "./Floater";
import Notice from "./Notice";
import Poster from "./Poster";

import ArtworkItem from "./ArtworkItem";

import { collections } from "../data/collections";

export default function Hero({ artworkAddress }) {
  useEffect(() => {
    document.body.style.background = "url('/assets/texture.jpg')";
  }, []);

  return (
    <div className='hero block relative full-width text-center clearfix' style={{ backgroundPosition: "top center", width: "100%", overflow: "hidden", backgroundRepeat: "no-repeat", marginTop: 0 }}>
      <ArtworkItem artworkAddress={artworkAddress} />
      

      <div className='pt-8 mt-2 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Other works in this collection
        </h3>
        <p className='mt-2 fontRegular mb-6'>A subline about what is below</p>

        <div className='collection-tiles clearfix pb-6'>
          <div className='column pt-4'>
            <Card image={"https://source.unsplash.com/random/300×300/?monkey,painting"} height={380} width={"100%"} />
            <div className='mt-2 hide-md'>
              <Poster image={"https://source.unsplash.com/random/300×300/?monkey&painting"} color={"orange"} height={152} width={"100%"} />
            </div>
          </div>
          <div className='column pt-8 hide-md'>
            <Card image={"https://source.unsplash.com/random/300×300/?painting"} height={418} width={"100%"} />
            <div className='mt-2'>
              <Poster image={"https://source.unsplash.com/random/300×300/?digital,art"} color={"#3B4664"} height={132} width={"100%"} />
            </div>
          </div>
          <div className='column'>
            <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={254} width={"100%"} />
            <div className='mt-2 hide-md'>
              <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={180} width={"100%"} />
            </div>
            <div className='mt-2'>
              <Card image={"https://source.unsplash.com/random/300×300/?art"} height={180} width={"100%"} />
            </div>
          </div>
        </div>

        <div className='button-group mb-8'>
          <Button type='primary' text='View the whole collection' hasIcon={true} icon='right-arrow' />

          <Button type='secondary' text='View Past Collections' />
        </div>
      </div>

      <div className='mt-8 pt-4 clearfix'>
        <div className='title-button' style={{ backgroundImage: "url(/assets/bg4.jpg)" }}>
          <div className='title-button-container'>
            <div className='title-text-group text-left'>
              <span className='pb-1 block fontExtraBold fontSizeN1 grey2 uppercase' style={{ letterSpacing: 2, color: "#fff" }}>
                TL;DR No more paying thousands for a jpeg.
              </span>
              <h3 style={{ margin: 0, padding: 0, maxWidth: "70%", fontSize: 34, lineHeight: "40px", paddingBottom: 16 }}>Link your wallet and start your collection today!</h3>
              <Button type='primary' text='Button' hasIcon={true} icon='right-arrow' />
            </div>

            <img src='https://source.unsplash.com/random/317×170/?crypto&wallet' style={{ width: 317, height: 170 }} />
          </div>
        </div>
      </div>

      <div className='pt-8 mt-2 mb-8 pb-8 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Past Collections
        </h3>
        <p className='mt-2 fontRegular actionSecondary'>Checkout what we've dropped in the past</p>

        <div className='grid-cards'>
          {collections.map((d, i) => {
            return <GridCard data={d} index={i} key={i} />;
          })}
        </div>
      </div>
    </div>
  );
}
