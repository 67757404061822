import React from "react"

export default function Logo({ address }) {
  return (
    <div className="user">
      {/* <img className="avatar" src="/assets/avatar.jpg" /> */}
      <div>
        <p></p>
        {address != null && address != "" && <p>0x...{address.substring(address.length - 5, address.length)}</p>}
      </div>
    </div>
  )
}
