import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useWeb3 } from "@3rdweb/hooks";
import Card from "../components/Card";
import GridCard from "../components/GridCard";
import Poster from "../components/Poster";
import Search from "../components/Search";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { collections } from "../data/collections";
import { useContract, getArtwork } from "../hooks";
import { artworkBase64ToJson, jsonFromArtwork } from "../util";

function Home(props) {
  const [artworks, setArtworks] = useState([]);

  const { address, provider } = useWeb3();
  const { contract: collection } = useContract("collection");

  useEffect(() => {
    document.body.style.background = "url('/assets/texture.jpg')";
  }, []);

  useEffect(() => {
    async function fetchCollection() {
      if (!collection || !address || !provider) return;

      const artworkCount = await collection.methods.artworkCount().call({ from: address });
      console.log(artworkCount);
      const _artworks = [];

      for (let i = 0; i < artworkCount; i++) {
        const artAddr = await collection.methods.artworks(i).call({ from: address });
        console.log(artAddr)
        const { contract: _artwork } = await getArtwork(artAddr, provider);
        const artworkJson = await jsonFromArtwork(_artwork);
        _artworks.push(artworkJson);
      }
      setArtworks(_artworks); 
    }
    fetchCollection();
  }, [provider, address, collection, collection?.methods]);

  function onSelect(index) {
    console.log(artworks)
	const artworkAddress = artworks[index].address
    props.history.push("/collection/drop1/" + artworkAddress);
  }

  return (
    <div>
      <Nav />

      <div className='pt-2 mt-2 clearfix'>
        <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
          Featured Collection
        </h3>
        <div className='full-width text-center'>
          <p className='mt-2 fontRegular actionSecondary mb-6'>NFTs done the Appian Way</p>
        </div>

        <Search />

        <div className='collection-tiles clearfix pb-6'>
          <div className='column' style={{ marginTop: 0 }}>
            <Card key='a1' artwork={artworks[0]} height={400} width={"100%"} onClick={() => onSelect(0)} />
            {/* <Card image={"https://source.unsplash.com/random/300×300/?monkey,painting"} height={400} width={"100%"} /> */}
            <div className='mt-2 hide-md'>
              <Poster artwork={artworks[4]} color={"orange"} height={260} width={"100%"} onClick={() => onSelect(4)}/>
              {/* <Poster image={"https://source.unsplash.com/random/300×300/?monkey&painting"} color={"orange"} height={260} width={"100%"} /> */}
            </div>
          </div>

          <div className='column hide-md' style={{ marginTop: 0 }}>
            <Card key='b2' artwork={artworks[1]} height={460} width={"100%"} onClick={() => onSelect(1)} />
            <div className='mt-2'>
              <Poster artwork={artworks[5]} color={"#3B4664"} height={200} width={"100%"} onClick={() => onSelect(5)} />
              {/* <Poster image={"https://source.unsplash.com/random/300×300/?digital,art"} color={"#3B4664"} height={200} width={"100%"} /> */}
            </div>
          </div>

          <div className='column' style={{ marginTop: 0 }}>
            <Card artwork={artworks[2]} height={300} width={"100%"} onClick={() => onSelect(2)} />
            {/* <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={300} width={"100%"} /> */}
            <div className='mt-2 hide-md'>
              <Card artwork={artworks[3]} height={180} width={"100%"} onClick={() => onSelect(3)} />
              {/* <Card image={"https://source.unsplash.com/random/300×300/?digital,art"} height={180} width={"100%"} /> */}
            </div>
            <div className='mt-2'>
              <Card artwork={artworks[3]} height={224} width={"100%"} onClick={() => onSelect(3)} />
              {/* <Card image={"https://source.unsplash.com/random/300×300/?art"} height={224} width={"100%"} /> */}
            </div>
          </div>
        </div>

        {/*<div className='button-group mb-8'>
		          <Button type='primary' text='View the whole collection' hasIcon={true} icon='right-arrow' />

		          <Button type='secondary' text='View Past Collections' />
		        </div>*/}

        {/* <div className='pt-8 mt-2 mb-8 pb-8 clearfix'>
          <h3 className='fontBold text-center actionSecondary mb-0 pb-0' style={{ fontSize: 60 }}>
            Past Collections
          </h3>
          <div className='full-width text-center'>
            <p className='mt-2 fontRegular actionSecondary'>Checkout what we've dropped in the past</p>
          </div>

          <div className='grid-cards'>
            {collections.map((d, i) => {
              return <GridCard data={d} index={i} key={i} />;
            })}
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(Home);
