import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";

import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Nav from "../components/Nav"

function Home(props) {
  
  return (
  	<div> 
        <Nav />
        <Hero />
        <Footer />
    </div>
  );
  
}

export default withRouter(Home)